/* eslint-disable */


export const ProductType = 'RPDDOF';

export enum ProductPermissions {
  STOREMGMT = 'STOREMGMT',
  DELETE_DAP = 'RACPAD_DAP_DELETE',
  SUBMIT_DAP = 'RACPAD_DAP_SUBMIT',
  CANCEL_DAP = 'RACPAD_DAP_CANCEL',
  CREATE_GOAL = 'RACPAD_DAP_GOAL_CREATE',
  EDIT_GOAL = 'RACPAD_DAP_GOAL_EDIT',
  // Permission for create, edit and unblock
  // All users have view permission
  BLOCK_TIMESLOT = 'RACPAD_DAP_EVENT_BLOCK',
  SUPPORT_USER = 'RACPAD_DAP_SUPPORT',
  DAP_VIEW_ONLY_TASKS_EVENTS = 'DAP_VIEW_ONLY_TASKS_EVENTS'
}
export const level4customerSignModuleName = 'LevelFourEmailSignAccess'


export const LOG_ACTIVITY_ERROR_MESSAGE =
  'Unable to log activity. Please try again later.';
export const API_ERROR_MESSAGE = 'Unable to fetch the data';
export const SELECT_ONE_OPTION = '<Select One>';
export enum WORKED_HISTORY_CALL_RESULT_CODES {
  LMAL = 'LMAL',
  LMCE = 'LMCE',
  LMWK = 'LMWK',
  LMR1 = 'LMR1',
  LMR2 = 'LMR2',
  LMR3 = 'LMR3',
  LMR4 = 'LMR4',
  NO_ANSWER = 'NA',
  FIELD_VISIT = 'FIELD',
}

export const STORE_NUMBER_KEY = 'storeNumber';
export enum CACHED_KEYS {
  TAKE_COMMITMENT_CACHED_KEY = 'commitment-cached-key',
  LOG_ACTIVITY_CACHED_KEY = 'log-activity-cached-key',
  FIELDVISITRESULT_CACHED_KEY = 'fieldvisitresult-cached-key',
  AM_ACTIVITY_LOG_SEARCH_KEY = 'am-activity-log-cached-key',
  PAST_DUE_SEARCH_KEY = 'past-due-list-cached-key',
  EDIT_CUSTOMER_INFORMATION_KEY = 'edit-customer-information-cached-key',
}

export enum WORKED_HISTORY_ACTIVITY_TYPE_CODES {
  CALL_CUSTOMER = 'CALLC',
  COMMITMENT = 'CMT',
  TEXT_SENT = 'TXTS',
  TEXT_RECEIVED = 'TXTR',
  CUST_SURVEY = 'CUSTSURV',
}

