/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Card,
    CircularProgress
} from "@rentacenter/racstrap";
import { DapCalendarStyles } from "./DapCalendarStyles";
import { Main } from "../Goals/Main/Main";
import { GetStatsStatus } from "../../api/user";
import Tab from "./Tab";
import Footer from "./Footer";
import Task from "./Task";
import Calender from "./Calender";
import { StoreMgmtContext } from "../../context/storeMgmtContext";

export function DapCalendar() {
    const classes = DapCalendarStyles();
    const [tab, setTab] = useState('calender')
    const [loader, setloader] = useState(true)
    const { TaskandGoals, setTaskandGoals, setGoals, setPriorityFieldVisitPhase2 } = useContext(StoreMgmtContext)

    useEffect(() => {
        getConfigDetailsFn()
    }, [])
    const getConfigDetailsFn = async () => {
        setloader(true)
        const storeNumber = window.sessionStorage.getItem('storeNumber')
        const payload: any = {
            storeNumbers: [window.sessionStorage.getItem('storeNumber')],
            paramKeyNames: ["TaskandGoals", "Goals", "PriorityFieldVisitPhase2"], //zzz
        };
        let getConfigDetailsRes
        if (storeNumber && storeNumber != null && storeNumber != '' && storeNumber != '' && storeNumber != 'null' && storeNumber != 'undefined') {
            getConfigDetailsRes = await GetStatsStatus(payload);
        }
        setloader(false)
        setTaskandGoals(getConfigDetailsRes?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.filter((el: any) => el.paramKeyName == 'TaskandGoals')[0].paramValue)
        setGoals(getConfigDetailsRes?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.filter((el: any) => el.paramKeyName == 'Goals')[0].paramValue);
        setPriorityFieldVisitPhase2(getConfigDetailsRes?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.filter((el: any) => el.paramKeyName == 'PriorityFieldVisitPhase2')[0].paramValue);
    }
    return (
        <>
            {loader ? <Grid
                style={{
                    position: 'fixed',
                    background: '#f7f5f5',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    opacity: 0.6,
                    zIndex: 2000,
                    textAlign: 'center',
                    margin: '0px 0px',
                }}
            >
                <Grid
                    style={{
                        display: 'block',
                        position: 'fixed',
                        zIndex: 9999999,
                        top: '40%',
                        right: '50%',
                    }}
                >
                    <CircularProgress />
                </Grid>
            </Grid> : null}

            <Grid className={classes.containerWid}>

                {/*Heading SubContainer*/}
                <Grid className={classes.floatLeft}>
                    <Grid className={classes.colMd12mt3}>
                        <Grid className={`${classes.row} ${classes.paidInMarginLeft}`} >
                            {/* Drawer total starts here */}
                            <Typography variant="h4" className={classes.header} >
                                Daily Activity Planner
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className={classes.containerWid}>
                    <div className={classes.racFixedFooterMargin}>
                        <Card className={classes.card} >

                            <Tab setTab={setTab} />
                            {/* {tab == 'calender' && !loader ? <Calender /> : !loader ? < Task /> : null} */}
                            {tab == 'calender' && !loader ? <Calender /> : tab == 'Task' && !loader ? < Task /> : tab == 'Goals' ? <Main /> : null}

                        </Card>
                    </div>
                </Grid>
            </Grid>
            {
                tab == 'calender' || tab == 'Task' ? <Footer createEvent={'createEvent'}
                    task={tab}
                /> : null
            }

        </>
    )
}
