import { ProductPermissions } from '../constants/constants';

export interface Error {
  code: string;
  message: string;
}

export interface APIError {
  traceId: string;
  requestId: string;
  errors: Error[];
}

export interface User {
  readonly id: string;
  readonly employeeId?: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly route: string;
  readonly permissions: ProductPermissions[];
}

export interface Action<T1 = any, T2 = any> {
  type: T1;
  payload?: T2;
}

export interface Option {
  label: string;
  value: string;
}

export interface ReferenceResponse {
  referenceKey: ReferenceKeys;
  referenceDetails: ReferenceItem[];
}

export interface ReferenceItem {
  readonly referenceCode: string;
  readonly displaySeq: number;
  readonly defaultValue: string;
  readonly description: string;
  readonly descSpanish?: string;
}

export enum ReferenceKeys {
  BEST_TIME_TO_CALL = 'CALL_TIME',
  COMMITMENT_STATUS = 'COMMITMENT_STATUS',
  LANGUAGE = 'LANGUAGE',
  ROUTE = 'ROUTE',
  CALL_RESULT = 'CALL_RESULT',
  FIELD_VISIT_RESULT = 'FIELD_VISIT_RESULT',
  COMMITMENT_TYPE = 'COMMITMENT_TYPE',

  COWORKER = 'COWORKER',

  AM_ACTIVITY = 'CALL_RESULT',
  PHONE_TYPE = 'PHONE_TYPE',
  DAYS_LATE = 'DAYS_LATE',
}

export interface ActivityPayload {
  storeNumber: string;
  activityDate: string;
  notes: string;
  callResultRefCode: string;
  fieldVisitResultRefCode?: string;
  commitmentId?: number;
  acctActivityRefCode: string;
  daysPastDue?: string;
  customerId: number;
  phoneNumberDialed?: string;
  coWorkerId: string;
  receiptId?: number;
  callTime: string;
}

export interface CustomerLocationState {
  customerIndex: number;
  commitmentDetails: any;
}
